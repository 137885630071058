import TestimonialCard from "./TestimonialCard";
import testimonialsList from "./testimonialsList";

const Testimonials = () => {
  return (
    <>
      <section
        id="testimonials"
        aria-label="Ce spun clientii nostri?"
        className="bg-slate-50 bg-gradient-to-b from-[#fdf5eb] to-white py-20 mt-[50px] lg:mt-[100px] text-[#493d13] "
      >
        <div className="mx-auto max-w-7xl px-5 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl md:text-center">
            <h2 className="font-[500] text-xl">Testimoniale</h2>
            <h1 className="font-bold text-3xl tracking-tight sm:text-4xl">
              Ce spun clientii nostri?
            </h1>
          </div>
          <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3">
            {testimonialsList.map((item) => (
              <TestimonialCard key={item.id} item={item} />
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};
export default Testimonials;
