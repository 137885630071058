import strudel from "../../assets/products/strudel.jpg";
import franzele from "../../assets/products/franzele.jpg";
import cozonac from "../../assets/products/cozonac.jpg";

const productsList = [
  {
    id: 1,
    image: franzele,
    title: "Franzele",
    description:
      " Franzela noastră este un simbol al tradiției și al calității, preparată cu ingrediente simple și naturale pentru a oferi un gust autentic și o textură pufosă. Coaptă cu grijă, are o crustă rumenă și crocantă, iar miezul este moale și aerat, perfect pentru orice masă",
  },
  {
    id: 2,
    image: strudel,
    title: "Strudel",
    description:
      "Savurați gustul autentic al unui strudel cu fructe de padure si vanilie, un desert tradițional cu aluat subțire și crocant si o umplutura care iti va umple gura de arome. Fiecare feliuță este o combinație perfectă între textura delicată a aluatului și aroma dulce și fructată a umpluturii. Coacerea lentă în cuptor face ca strudelul să capete o culoare aurie și o fragranță irezistibilă.",
  },
  {
    id: 3,
    image: cozonac,
    title: "Cozonac",
    description:
      "Cozonacul nostru este o adevărată delicatesă, pregătit cu dragoste și ingrediente naturale de cea mai bună calitate. Aluatul pufos și fin se împletește perfect cu umplutura bogată de nuci, cacao, stafide și esență de vanilie, creând un desert care îți răsfață simțurile cu fiecare mușcătură.",
  },
];
export default productsList;
