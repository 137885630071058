const Home = () => {
  return (
    <>
      <div className="home-banner">
        <div className="container mx-auto flex justify-center items-center h-[650px] px-5">
          <div className="home-content flex flex-col items-center">
            <h1 className="font-[700] text-white text-center text-[44px] md:text-[64px] lg:max-w-[768px]">
              Pâine și produse de panificație
            </h1>
            <a
              href="#products"
              className="mt-5 inline-block rounded-xl bg-[#eaa636] px-12 py-3 text-md font-medium text-white transition hover:bg-yellow-500"
            >
              Descoperă produsele
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
