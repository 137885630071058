import aboutImage from "../../assets/about-image.PNG";
const About = () => {
  return (
    <>
      <div
        id="about"
        className="container mx-auto flex flex-col lg:flex-row items-center mt-[50px] lg:mt-[100px] px-5"
      >
        <div className="hidden lg:block basis-1/2 mr-5">
          <img src={aboutImage} alt={aboutImage}></img>
        </div>
        <div className="flex flex-col basis-1/2 text-[#493d13]">
          <h2 className="font-[500] text-xl">Despre noi</h2>
          <h1 className="font-[600] text-[28px] lg:text-[36px]">
            Coacem fiecare produs <br />
            din inima noastră
          </h1>
          <p className="mt-5 text-gray-500">
            Brutăria noastră – Locul unde tradiția întâlnește prospețimea
          </p>
          <p className="mt-5 text-gray-500">
            Bine ați venit la brutăria noastră, locul în care pasiunea pentru
            panificație se transformă în produse de calitate superioară,
            pregătite cu grijă și respect pentru fiecare detaliu. Folosind doar
            ingrediente naturale, fără aditivi sau conservanți, aducem în
            fiecare zi pe masa dumneavoastră pâine proaspăt coaptă, produse de
            patiserie fine și delicii care răsfață simțurile.
          </p>
        </div>
        <div className="lg:hidden basis-1/2 mt-10">
          <img src={aboutImage} alt={aboutImage}></img>
        </div>
      </div>
    </>
  );
};
export default About;
