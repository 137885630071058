import React, { useState } from "react";
import "boxicons";

import Logo from "../../assets/logo.png";
import NavItem from "./NavItem";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {/* bg-[#fff5ed] */}
      <header className="">
        <nav className="container mx-auto px-5">
          <div className="flex justify-between items-center font-[600]">
            <div>
              <a href="#">
                <img src={Logo} className="w-full"></img>
              </a>
            </div>
            <div className="hidden lg:block">
              <ul className="flex space-x-5 lg:space-x-10">
                <NavItem link={"#about"} item={"Despre"} />
                <NavItem link={"#products"} item={"Produse"} />
                <NavItem link={"#services"} item={"Servicii"} />
                <NavItem link={"#testimonials"} item={"Recomandari"} />
              </ul>
            </div>
            <div className="hidden lg:flex items-center space-x-5">
              <box-icon
                type="regular"
                name="phone-call"
                border="circle"
                size="md"
                animation="tada"
                color="#493d13"
              ></box-icon>
              <NavItem
                link={"tel:+0742347484"}
                item={`Contacteaza-ne\n0742 347 484`}
              />
            </div>

            <div className="lg:hidden">
              <button className="focus:outline-none" onClick={toggleNavbar}>
                <box-icon
                  name="menu-alt-right"
                  color="#111827"
                  size="md"
                ></box-icon>
              </button>
            </div>
          </div>
          {/* Mobile Navigation Menu */}
          <div
            className={
              isOpen
                ? "fixed lg:hidden left-0 top-[79px] w-full h-full bg-[#fff5ed] ease-in-out duration-500"
                : "ease-in-out w-full duration-500 fixed top-[79px] bottom-0 left-[-100%]"
            }
          >
            <ul className="container mx-auto font-[600] space-y-2 text-lg mt-5 px-10">
              {/* Mobile Navigation Items */}
              <NavItem item={"Acasa"} />
              <NavItem item={"Despre"} />
              <NavItem item={"Produse"} />
              <NavItem item={"Servicii"} />
              <NavItem item={"Recomandari"} />
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Navbar;
