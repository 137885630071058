const ServiceCard = ({ item }) => {
  return (
    <>
      <div class="block flex flex-col items-center rounded-xl border border-gray-100 bg-white p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring shadow-xl shadow-slate-900/10">
        <span class="inline-block rounded-lg bg-gray-50 p-3">
          <box-icon
            name={item.image}
            type="solid"
            color="#493d13"
            border="circle"
            size="md"
          ></box-icon>
        </span>

        <h2 class="mt-2 font-[600] text-[#493d13]">{item.service}</h2>

        <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-500">
          {item.description}
        </p>
      </div>
    </>
  );
};
export default ServiceCard;
