import "boxicons";
const ProductCard = ({ item }) => {
  return (
    <>
      <div class="relative flex flex-col my-10 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-72 sm:w-96">
        <div
          class="relative h-[300px] bg-cover bg-center bg-no-repeat mx-2 md:mx-4 -mt-6 overflow-hidden shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40"
          style={{ backgroundImage: `url(${item.image})` }}
        ></div>
        <div class="p-6 text-left">
          <h5 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-[#493d13]">
            {item.title}
          </h5>
          <p class="block text-sm  leading-relaxed text-gray-500 ">
            {item.description}
          </p>
        </div>
      </div>
    </>
  );
};
export default ProductCard;
