const testimonialsList = [
  {
    id: 1,
    text: "Produse de calitate superioară și mereu proaspete! Pâinea și patiseria sunt absolut delicioase, iar serviciul este impecabil. Recomand cu încredere!",
    author: "Mihai C.",
    stars: 5,
  },
  {
    id: 2,
    text: "Am descoperit această brutărie recent și sunt încântat! Pâinea are un gust autentic, iar croissantele sunt cele mai bune pe care le-am gustat vreodată. Voi reveni cu siguranță!",
    author: "Janina M.",
    stars: 5,
  },
  {
    id: 3,
    text: "Brutăria preferată! Mereu găsesc produse proaspete și gustoase. Îmi place că au și livrare rapidă la domiciliu, iar torturile personalizate sunt absolut minunate.",
    author: "Camelia T.",
    stars: 5,
  },
];
export default testimonialsList;
