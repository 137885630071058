import About from "../About/About";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Navbar from "../Navbar/Navbar";
import Products from "../Products/Products";
import Services from "../Services/Services";
import Testimonials from "../Testimonials/Testimonials.jsx";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Services />
      <Products />
      <Testimonials />
      <Footer />
    </>
  );
};
export default Layout;
