import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <>
      <footer className="border-t border-t-[#ffcc77]">
        <div className=" container mx-auto px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-3">
            <div>
              <div className="flex justify-center text-[#f29c52] sm:justify-start">
                <img src={logo} className=""></img>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-8 grid-cols-2 lg:grid-cols-3 lg:col-span-2">
              <div className="">
                <p className="text-lg font-medium text-[#493d13]">Legal</p>

                <ul className="mt-8 space-y-0 text-sm">
                  <li>
                    <a
                      href="https://anpc.ro/ce-este-sal/"
                      target="_blank"
                      rel="nofollow"
                    >
                      <img
                        src="https://wpfitness.eu/wp-content/uploads/2022/10/anpc-sal.png"
                        alt="Solutionarea Alternativa a Litigiilor"
                        className="max-w-[175px]"
                      ></img>
                    </a>
                    <br />
                  </li>

                  <li>
                    <a
                      href="https://ec.europa.eu/consumers/odr"
                      target="_blank"
                      rel="nofollow"
                    >
                      <img
                        className="max-w-[175px]"
                        src="https://wpfitness.eu/wp-content/uploads/2022/10/anpc-sol.png"
                        alt="Solutionarea Online a Litigiilor"
                      ></img>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="">
                <p className="text-lg font-medium text-[#493d13]">Contact</p>

                <ul className="mt-8 space-y-4 text-sm">
                  <li>
                    <a
                      className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 text-[#493d13]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>

                      <span className="flex-1 text-gray-500">
                        contact@panifly.ro
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 text-[#493d13]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>

                      <span className="flex-1 text-gray-500">
                        <a href="tel:+0742347484">+0742347484</a>
                      </span>
                    </a>
                  </li>

                  <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-5 shrink-0 text-[#493d13]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>

                    <address className="-mt-0.5 flex-1 not-italic text-gray-500">
                      Lieşti, Lieşti, 807180, Galaţi
                    </address>
                  </li>
                </ul>
              </div>
              <div className="">
                <p className="text-lg font-medium text-[#493d13]">Servicii</p>

                <ul className="mt-8 space-y-4 text-sm">
                  <li>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d697.5619392391397!2d27.535661669667086!3d45.62575893608336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b697f420aafd0b%3A0x8f36f4ea1dc7fd70!2sStrada%20Brut%C4%83riei%20833%2C%20Lie%C8%99ti%20807180!5e0!3m2!1sro!2sro!4v1712054650280!5m2!1sro!2sro"
                      width="auto"
                      height="auto"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-12 border-t border-gray-100 pt-6">
            <div className="text-center sm:flex sm:justify-between sm:text-left">
              <p className="text-sm text-[#493d13]">
                <span className="block sm:inline">
                  Toate drepturile rezervate<span> &middot; </span>
                </span>
              </p>

              <p className="mt-4 text-sm text-[#493d13] sm:order-first sm:mt-0">
                &copy; 2024 | Panifly.ro
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
