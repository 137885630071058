import React from "react";

import ProductCard from "./ProductCard";
import productsList from "./productsList";

const Products = () => {
  return (
    <>
      <div
        id="products"
        className="container mx-auto mt-[50px] lg:mt-[100px] px-5"
      >
        <div className="flex flex-col items-center text-[#493d13]">
          <h2 className="font-[500] text-xl">Produse</h2>
          <h1 className="font-[600] text-[28px] mb-5 lg:text-[36px] text-center max-w-[400px] md:max-w-[500px]">
            Explorați produsele noastre de panificație
          </h1>
          <div className="container mx-auto px-5 flex flex-col md:flex-row items-center md:items-start justify-around gap-5">
            {productsList.map((item) => (
              <ProductCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Products;
