import ServiceCard from "./ServiceCard";
import servicesList from "./servicesList";
const Services = () => {
  return (
    <>
      <section id="services" className="wheat-banner mt-[50px] lg:mt-[100px]">
        <div class="container mx-auto px-5 py-8">
          <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
            <div class="max-w-lg text-left lg:mx-0 text-[#493d13]">
              <h2 className="font-[500] text-xl">Servicii</h2>

              <h1 class="text-3xl font-bold sm:text-4xl">Ce putem oferi?</h1>

              <p class="mt-4 text-gray-500">
                La brutăria noastră, ne dedicăm să oferim clienților nu doar
                produse de panificație de cea mai bună calitate, dar și servicii
                care să răspundă nevoilor tale zilnice. Fie că ești în căutarea
                unei pâini calde pentru micul dejun, a unor produse de patiserie
                fine pentru o gustare sau a unui tort special pentru un
                eveniment important, suntem aici să îți oferim tot ce ai nevoie
                pentru a transforma fiecare masă într-o experiență delicioasă.
              </p>
            </div>

            <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {servicesList.map((item) => (
                <div key={item.id}>
                  <ServiceCard item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Services;
