const servicesList = [
  {
    id: 1,
    image: "baguette",
    service: "Panificatie",
  },
  {
    id: 2,
    image: "cake",
    service: "Patiserie",
  },
  {
    id: 3,
    image: "truck",
    service: "Transport",
  },
];
export default servicesList;
