const NavItem = ({ item, link }) => {
  return (
    <>
      <a
        href={link}
        className="whitespace-pre-line text-[#493d13] cursor-pointer relative text-md lg:text-lg w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#493d13] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-500 after:origin-left"
      >
        {item}
      </a>
    </>
  );
};
export default NavItem;
